<template>
  <div class="outside">
    <div class="box">
      <div class="container-fluid">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.outside {
  @media screen and (max-width: 767px) {
    &::before {
      content: "";
      background-image: url("/img/backgroundxs.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    &::before {
      content: "";
      background-image: url("/img/backgroundsm.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    &::before {
      content: "";
      background-image: url("/img/background.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  width: 100vw;
  height: 100vh;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 33px 130px 33px 95px;
  z-index: 1;
}

@media (max-width: 992px) {
  .box {
    padding: 33px 90px 33px 85px;
  }
}

@media (max-width: 768px) {
  .box {
    padding: 33px 80px 33px 80px;
  }
}

@media (max-width: 576px) {
  .box {
    padding: 33px 15px 33px 15px;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
